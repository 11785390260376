import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Accordion from '../../../../components/Accordion.js'
import Layout from '../../../../components/layout'

const GaneshP = ({data}) => {
    return <Layout>
        <Helmet>
            <title>Pandit for Ganpati Pooja in Bangalore, India: Book Pandit in Your Language</title>
            <meta name="description" content="Need Pandit for Ganesh Pooja in Bangalore, India? We provide the Best Tumeric Pooja Services for Lord Ganapati at Home and for new house in Bangalore, India for both North and South Indians." />
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "Service",
                  "serviceType": "Pandit for Ganesh Puja in Bangalore",
                  "provider": {
                    "@type": "LocalBusiness",
                    "name": "NamasteGod",
                    "telephone": "(+91) 81972 49957",
                    "priceRange": "₹₹₹",
                    "url": "https://www.namastegod.com",
                    "image": "https://lh4.googleusercontent.com/-EXCsRc5tnmc/AAAAAAAAAAI/AAAAAAAAAAA/u-HcCeFyTic/s44-p-k-no-ns-nd/photo.jpg",
                    "address":
                    {
                      "@type": "PostalAddress",
                      "addressLocality": "Bangalore",
                      "addressRegion": "KA",
                      "addressCountry": "IN",
                      "postalCode": "560078",
                      "streetAddress": "#142/1, 17th Cross, 18th Main, JP Nagar 5th Phase"
                     }
                  },
                  "areaServed": [
                    {
                      "@type": "City",
                      "name": "Bangalore",
                      "@id": "https://en.wikipedia.org/wiki/Bangalore"
                    }
                  ]
                }
            `}</script>
        </Helmet>
        <div id="main" className="pandit-listing">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1 className="desktop">Pandit for Ganpati Pooja in Bangalore<br/>India: Book Pandit in Your Language</h1>
                        <h1 className="mobile">Pandit for Ganpati Pooja in<br/>Bangalore India: Book Pandit<br/>in Your Language</h1>
                    </header>
                    <a href="https://g.co/kgs/MciV3q">
                        <span className="reviews">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <span>Reviews</span>
                        </span>
                    </a>
                    <p>Need <a href='https://en.wikipedia.org/wiki/Purohit' className='no-highlight'>Pandit</a> for Ganesh Pooja in Bangalore, India? We provide the Best Tumeric Pooja Services for Lord Ganapati at Home and for new house in Bangalore, India for both North and South Indians.</p>
                </div>
            </section>
            <section id="two" className="spotlights">
                {data.allFile.nodes.map((value, index) => {
                    return <section>
                        <div className="image">
                            <img src={value.childImageSharp.fluid.src} alt={data.allMarkdownRemark.edges[index].node.frontmatter.title} title={data.allMarkdownRemark.edges[index].node.frontmatter.title}/>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>{data.allMarkdownRemark.edges[index].node.frontmatter.title}</h3>
                                </header>
                                <div className="details">
                                    <span className="label">Years of Experience: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.experience}</span><br/>
                                    <span className="label">Completed his Degree: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.degree}</span><br/>
                                    <span className="label">Well Versed in Puja Traditions as per Regions: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.regions_all}</span><br/>
                                    <span className="label">In Bangalore since: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.bangalore}</span><br/>
                                    <span className="label">Specialized In: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.specialized}</span><br/>
                                    <span className="label">Puja’s Performed: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.pujas}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                })}
            </section>
        </div>

    </Layout>
}

export const query = graphql`
{
  allMarkdownRemark(sort: {fields: frontmatter___order})  {
    edges {
      node {
        frontmatter {
          order
          title
          experience
          degree
          regions_all
          bangalore
          specialized
          pujas
        }
      }
    }
  }
  allFile(filter: {name: {in: ["Pandit Ashutosh Pandey", "Pandit Bharat Kumar", "Pandit Hem Chandra Joshi", "Pandit Jayarama Sharma", "Pandit Subodh Pandey", "Pandit TManjunath"]}}, sort: {fields: name}) {
    nodes {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}`

export default GaneshP